var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column is-narrow-mobile" }, [
    _c("div", { staticClass: "card" }, [
      _c(
        "header",
        { staticClass: "card-header is-flex is-flex--space-between" },
        [
          _c("div", [
            _c(
              "p",
              { staticClass: "card-header-title is-size-5 has-text-grey" },
              [_vm._v(_vm._s(_vm.$t("organizations.title")))]
            )
          ]),
          _c("div", { staticClass: "is-flex is-flex--center" }, [
            _c(
              "div",
              { staticClass: "is-flex is-flex--space-between" },
              [
                _c("app-input-search", {
                  on: { "on-text-enter": _vm.searchFilteredOrganizations }
                })
              ],
              1
            ),
            _c(
              "a",
              {
                staticClass: "card-header-icon",
                attrs: { "aria-label": "more options" }
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button is-accent is-small",
                    attrs: { to: { name: "organizationCreate" } }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fas fa-plus" })
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("forms.buttons.addOrganization")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "card-content" },
        [
          _c(
            "b-table",
            {
              attrs: {
                loading: _vm.requestOrganizations,
                "backend-pagination": true,
                total: _vm.totalOrganizations,
                data: _vm.organizations.length ? _vm.organizations : [],
                "per-page": _vm.organizationsPagination.perPage,
                paginated: true,
                "pagination-size": "is-small",
                striped: ""
              },
              on: { "page-change": _vm.triggerAsyncPageChange },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "id",
                            label: "ID",
                            width: "40",
                            numeric: "",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(props.row.id))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "name",
                            label: "Organización",
                            centered: ""
                          }
                        },
                        [_vm._v(_vm._s(_vm.getCleanName(props.row.name)))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: { field: "tier", label: "Tier", centered: "" }
                        },
                        [_vm._v("T" + _vm._s(props.row.tier))]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "created",
                            label: "Creación",
                            centered: ""
                          }
                        },
                        [
                          _c("span", { staticClass: "tag is-info" }, [
                            _vm._v(
                              _vm._s(
                                new Date(props.row.created).toLocaleDateString()
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "status",
                            label: "Estado",
                            centered: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "tag",
                              class: props.row.enabled
                                ? " is-success"
                                : "is-danger"
                            },
                            [_vm._v(_vm._s(_vm.$t("tables.status")))]
                          )
                        ]
                      ),
                      _c(
                        "b-table-column",
                        { attrs: { label: "Aplicaciones", centered: "" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.renderApplicationNames(props.row.products)
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "name",
                            label: "Organización padre",
                            centered: ""
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.renderOrgParentName(props.row.parentName)
                            )
                          )
                        ]
                      ),
                      _c(
                        "b-table-column",
                        {
                          attrs: {
                            field: "actions",
                            label: "Acciones",
                            centered: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "is-flex is-flex--center" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "has-text-info",
                                  on: {
                                    click: function($event) {
                                      return _vm.goToOrganizationEdition(
                                        props.row.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: _vm.$t("tables.edit"),
                                        position: "is-top",
                                        type: "is-info",
                                        size: "is-small",
                                        animated: "",
                                        square: ""
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "has-padding",
                                        attrs: {
                                          pack: "fas",
                                          icon: "edit",
                                          size: "is-small"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "has-text-dark",
                                  on: {
                                    click: function($event) {
                                      return _vm.goToOrganizationConfiguration(
                                        props.row.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: _vm.$t("tables.config"),
                                        position: "is-top",
                                        type: "is-dark",
                                        size: "is-small",
                                        animated: "",
                                        square: ""
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "has-padding",
                                        attrs: {
                                          pack: "fas",
                                          icon: "cogs",
                                          size: "is-small"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "empty" }, [
                _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.requestOrganizationError &&
                                !_vm.requestOrganizations,
                              expression:
                                "!requestOrganizationError && !requestOrganizations"
                            }
                          ]
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              pack: "fas",
                              icon: "exclamation",
                              size: "is-large"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.requestOrganizationError &&
                                !_vm.requestOrganizations,
                              expression:
                                "!requestOrganizationError && !requestOrganizations"
                            }
                          ]
                        },
                        [_vm._v(_vm._s(_vm.$t("warnings.accountNoData")))]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.requestOrganizationError,
                          expression: "requestOrganizationError"
                        }
                      ],
                      staticClass: "content has-text-danger has-text-centered"
                    },
                    [
                      _c("p", [
                        _c("span", { staticClass: "icon is-large" }, [
                          _c("i", { staticClass: "far fa-frown fa-3x" })
                        ])
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("errors.requestError")))])
                    ]
                  )
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }