<template>
  <div class="column is-narrow-mobile">
    <div class="card">
      <header class="card-header is-flex is-flex--space-between">
        <div>
          <p class="card-header-title is-size-5 has-text-grey">{{ $t('organizations.title') }}</p>
        </div>
        <div class="is-flex is-flex--center">
          <div class="is-flex is-flex--space-between">
            <!-- <div>
              <b-field>
                <b-select
                  v-model="orgType"
                  expanded
                  placeholder="Organization type"
                  icon="sitemap"
                  size="is-small"
                >
                  <option value="0">All</option>
                  <option value="1">Publisher</option>
                  <option value="2">Advertiser</option>
                </b-select>
              </b-field>
            </div>-->

            <app-input-search @on-text-enter="searchFilteredOrganizations"></app-input-search>
          </div>
          <a class="card-header-icon" aria-label="more options">
            <router-link :to="{name: 'organizationCreate'}" class="button is-accent is-small">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>{{ $t('forms.buttons.addOrganization') }}</span>
            </router-link>
          </a>
        </div>
        <!-- <a class="card-header-icon" aria-label="more options">
          <a class="button is-small">
            <span class="icon">
              <i class="fas fa-download"></i>
            </span>
          </a>
        </a>-->
      </header>
      <div class="card-content">
        <b-table
          :loading="requestOrganizations"
          :backend-pagination="true"
          :total="totalOrganizations"
          :data="organizations.length ? organizations : []"
          :per-page="organizationsPagination.perPage"
          :paginated="true"
          pagination-size="is-small"
          striped
          @page-change="triggerAsyncPageChange"
        >
          <template slot-scope="props">
            <b-table-column field="id" label="ID" width="40" numeric centered>{{ props.row.id }}</b-table-column>
            <!-- <b-table-column field="image" label="Avatar" width="48" centered>
              <figure v-if="props.row.image" class="is-flex is-flex--center">
                <p class="image is-32x32">
                  <img :src="getUrlImage(props.row.image)" class="is-rounded" />
                </p>
              </figure>
              <span v-else class="icon is-medium">
                <span class="fa-stack">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-user fa-stack-1x fa-inverse"></i>
                </span>
              </span>
            </b-table-column> -->
            <b-table-column field="name" label="Organización" centered>{{ getCleanName(props.row.name) }}</b-table-column>
            <b-table-column field="tier" label="Tier" centered>T{{ props.row.tier }}</b-table-column>
            <b-table-column field="created" label="Creación" centered>
              <span class="tag is-info">{{ new Date(props.row.created).toLocaleDateString() }}</span>
            </b-table-column>
            <b-table-column field="status" label="Estado" centered>
              <span
                :class="props.row.enabled? ' is-success': 'is-danger'"
                class="tag"
              >{{ $t('tables.status') }}</span>
            </b-table-column>
            <b-table-column label="Aplicaciones" centered>
              <span>{{ renderApplicationNames(props.row.products) }}</span>
            </b-table-column>
            <b-table-column field="name" label="Organización padre" centered>{{ renderOrgParentName(props.row.parentName) }}</b-table-column>
            <b-table-column field="actions" label="Acciones" centered>
              <div class="is-flex is-flex--center">
                <a class="has-text-info" @click="goToOrganizationEdition(props.row.id)">
                  <b-tooltip
                    :label="$t('tables.edit')"
                    position="is-top"
                    type="is-info"
                    size="is-small"
                    animated
                    square
                  >
                    <b-icon class="has-padding" pack="fas" icon="edit" size="is-small"></b-icon>
                  </b-tooltip>
                </a>
                <a class="has-text-dark" @click="goToOrganizationConfiguration(props.row.id)">
                  <b-tooltip
                    :label="$t('tables.config')"
                    position="is-top"
                    type="is-dark"
                    size="is-small"
                    animated
                    square
                  >
                    <b-icon class="has-padding" pack="fas" icon="cogs" size="is-small"></b-icon>
                  </b-tooltip>
                </a>
              </div>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p v-show="!requestOrganizationError && !requestOrganizations">
                  <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
                </p>
                <p
                  v-show="!requestOrganizationError && !requestOrganizations"
                >{{ $t('warnings.accountNoData') }}</p>
              </div>
              <div
                v-show="requestOrganizationError"
                class="content has-text-danger has-text-centered"
              >
                <p>
                  <span class="icon is-large">
                    <i class="far fa-frown fa-3x"></i>
                  </span>
                </p>
                <p>{{ $t('errors.requestError')}}</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
    <!-- <b-modal :active.sync="modalActive" class="has-modal-card">
      <app-account-modal :account-id="accountId"></app-account-modal>
    </b-modal>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputSearch from '@/common/widgets/search/InputSearch'

export default {
  name: 'accountTable',
  components: {
    'app-input-search': InputSearch
  },
  data() {
    return {
      modalActive: false,
      orgType: 2
    }
  },
  computed: {
    ...mapGetters([
      'organizations',
      'organizationsPagination',
      'totalOrganizations',
      'requestOrganizations',
      'requestOrganizationError',
      'tokenValid'
    ])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  created() {
    if (!this.$route.query.hasOwnProperty('q') || !this.$route.query.hasOwnProperty('type')) {
      this.getOrganizations({ page: 1, size: process.env.VUE_APP_PAGE_SIZE })
    }
  },
  methods: {
    ...mapActions(['getOrganizations']),
    getUrlImage(imagePath) {
      let urlBase64Image = `${process.env.VUE_APP_CDN_URL}/images/${imagePath}`
      return urlBase64Image
    },
    goToOrganizationEdition(idOrganization) {
      this.$router.push({ name: 'organizationEdit', params: { id: `${idOrganization}` } })
    },
    goToOrganizationConfiguration(idOrganization) {
      this.$router.push({ name: 'organizationApps', params: { id: `${idOrganization}` } })
    },
    renderApplicationNames(products = []) {
      let buildedAppNames = ''
      if (products.length) {
        buildedAppNames = products
          .filter(product => product.id !== 1 && product.enabled)
          .map(product => product.name)
          .join(', ')
      }
      return buildedAppNames.length > 0 ? buildedAppNames : 'Sin aplicaciones'
    },
    renderOrgParentName(parentName) {
      if (parentName == null) {
        return ' '
      }
      return parentName
    },
    renderBrokenImage() {
      console.log('broken link!')
    },
    searchFilteredOrganizations({ textToSearch, type }) {
      let pageSize = process.env.VUE_APP_PAGE_SIZE
      this.getOrganizations({ page: 1, size: pageSize, q: textToSearch, type })
    },
    triggerAsyncPageChange(page) {
      let pageSize = process.env.VUE_APP_PAGE_SIZE
      // let pageSize = 0
      if (this.$route.query.hasOwnProperty('q')) {
        if (this.$route.query.hasOwnProperty('type')) {
          console.log('triggerAsyncPageChange -> page', page)
          console.log('triggerAsyncPageChange -> size', pageSize)
          this.getOrganizations({ page: page, size: pageSize, q: this.$route.query.q, type: this.$route.query.type })
        } else {
          this.getOrganizations({ page: page, size: pageSize, q: this.$route.query.q })
        }
      } else {
        this.getOrganizations({ page: page, size: pageSize })
      }
    },
    getCleanName(name) {
      if (!name.includes('_prod_')) {
        return name
      } else {
        const idConverged = name.split('_', 1)[0]
        return 'Converged_' + idConverged
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
